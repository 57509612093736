import { ENTITY_TYPES, USER_COURSE_TYPES } from 'common';
import PATHS from 'constants/paths';
import { generatePath } from 'react-router-dom';

const { member } = USER_COURSE_TYPES.userCourseRole;

const userMenu = (user) => [
  {
    name: 'Explore',
    submenu: [
      {
        name: ENTITY_TYPES.LEARNING_COMMUNITY.singular,
        path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.LEARNING_COMMUNITY.key }),
      },
      {
        name: ENTITY_TYPES.DEPARTMENT.singular,
        path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.DEPARTMENT.key }),
      },
      {
        name: ENTITY_TYPES.ORGANIZATION.singular,
        path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.ORGANIZATION.key }),
      },
    ],
    renderDivider: false,
  },
  {
    name: 'Member',
    path: `${generatePath(PATHS.DASHBOARD, { category: ENTITY_TYPES.LEARNING_COMMUNITY.key, role: member })}`,
    renderDivider: false,
  },
  {
    name: 'Organizer',
    path: PATHS.LEARNING_COMMUNITIES_DASHBOARD,
    renderDivider: true,
  },

  {
    name: 'Profile',
    path: generatePath(PATHS.PROFILE, { userName: user?.userName }),
    renderDivider: false,
  },
  {
    name: 'Members',
    path: generatePath(PATHS.EXPLORE, { category: ENTITY_TYPES.MEMBER.key }),
    renderDivider: true,
  },
];

export default userMenu;
